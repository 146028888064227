<template>
  <div>
    <!-- <v-img
      :src="isOnPC ? coverPictures.desktop : coverPictures.mobile"
      class="align-center"
    > -->
    <v-container>
      <h2 class="text-h3">E-Layanan GKBI</h2>
      <div class="text-justify">
        <p>
          Saat ini sudah tersedia layanan  untuk jemaat dimana jemaat sudah bisa mengisi formulir secara online.
          Layanan hanya dapat digunakan jika jemaat telah Login pada website.
          E-layanan terdiri dari 2 bagian yaitu layanan surat dan layanan database.
          Untuk Layanan surat terdiri dari 3 surat yaitu Surat Baptisan, Surat Penyerahan Anak dan Surat Pernikahan.
          Untuk layanan Database terdiri dari Data Jemaat, Data Setoran ke Sinode, Data Arsip Surat Sinode.
        </p>
        <ul>
          <li>
            <h3 class="text-h6">E-layanan Surat</h3>
            <p>
              Dalam e-layanan surat, jemaat mengisi formulir yang tersedia.
              Setelah surat telah selesai akan diberitahukan lewat telepon atau e-mail.
              Untuk anggota jemaat yang berada di luar Sulawesi Utara, surat akan dikirim melalui e-mail dan WhatsApp.
              Surat dicetak menggunakan format kertas cover berwarna putih dengan ukuran kertas F4.
            </p>
          </li>
          <li>
            <h3 class="text-h6">E-layanan Database</h3>
            <ul>
              <li>
                <h4 class="text-h7">Database Jemaat</h4>
                <p>
                  Tersedia formulir untuk diisi.
                  Diharapkan untuk cek terlebih dahulu apakah nama sudah ada dalam database.
                  Jika terdapat kekeliruan pengisian data, jemaat dapat menghubungi kontak yang tersedia.
                </p>
              </li>
              <li>
                <h4 class="text-h7">Database Setoran</h4>
                <p>
                  Dalam database setoran, jemaat dapat melihat data-data setoran ke Sinode untuk tiap jemaat.
                  Untuk pengisian formulir hanya dapat di isi oleh bendahara BPJ.
                  Bendahara BPJ diharapkan dapat mengirim alamat email login ke kontak yang tersedia.
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ELayanan',

  data () {
    return ({
      coverPictures: {
        mobile: 'https://storage.googleapis.com/gkbi-prime.appspot.com/front-page/elayanan%20jemaat.jpg',
        desktop: 'https://storage.googleapis.com/gkbi-prime.appspot.com/front-page/elayanan%20jemaat-1600x900.jpg'
      }
    })
  }
}
</script>
